<template>
  <b-row class="p-4 bg-mellow">
    <b-col cols="12" class="pb-4">
      <h1 class="d-inline-block pr-1 h3">{{ event.nwpName }}: Einzelanmeldung</h1>
    </b-col>
    <b-col cols="12">
      <EventEinzelanmeldung :key="eventEinzelanmeldungsKey" v-on:reset-einzelanmeldung="eventEinzelanmeldungsKey++" v-if="event" :event="event" />
    </b-col>
  </b-row>
</template>

<script>
import EventEinzelanmeldung from "@/views/Formulare/EventEinzelanmeldung";

export default {
  components: {
    EventEinzelanmeldung,
  },
  data() {
    return {
      event: null,
      eventEinzelanmeldungsKey: 1, // Key wird verwendet um beim Component EventRegistrierung einen Reload zu forcieren (Änderung des Keys forciert reload)
    };
  },
  props: {
    eventId: {
      type: String,
      default: ''
    }
  },
  created() {
    this.getEventData();
  },
  methods: {
    getEventData() {
      const id = this.eventId;
      const events = this.$store.getters["wettkampf/getEvents"];
      for (let i = 0; i < events.length; i++) {
        if (events[i].id == id) {
          this.event = events[i];
          break; // Verlassen den for-loop, wenn der passende Event gefunden werden konnte
        }
      }
      if (!this.event) {
        this.$router.push(`/${this.$i18n.locale}/veranstaltungen/`);
      }
    },
  }
};
</script>
