<template>
  <b-row>
    <b-col cols="12" v-show="displayKidRegisterForm">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
        <b-form @submit.prevent="handleSubmit(registerAthleteNumber)">
          <b-row>
            <!-- Feld: Athleten Nummber -->
            <b-col sm="6">
              <ValidationProvider
                name="Swiss Athletics Nummer"
                rules="required"
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-satMemberNumber"
                  label="Swiss Athletics Nummer *"
                  label-for="input-satMemberNumber"
                >
                  <b-form-input
                    id="input-satMemberNumber"
                    v-model="formKid.satMemberNumber"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-satMemberNumber"
                    size="sm"
                  ></b-form-input>
                  <b-form-invalid-feedback id="feedback-satMemberNumber">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            
            <!-- Feld: Geburtstag -->
            <b-col sm="6">
              <ValidationProvider
                name="Geburtstag"
                rules="required|birthdate"
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-birthDate"
                  label="Geburtstag *"
                  label-for="input-birthDate"
                >
                  <b-form-input
                    id="input-birthDate"
                    v-model="formKid.birthDate"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-birthDate"
                    size="sm"
                    placeholder="tt.mm.jjjj"
                  ></b-form-input>
                  <b-form-invalid-feedback id="feedback-birthDate">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col v-show="formKidFeedback.display">
              <b-alert :variant="formKidFeedback.type ? formKidFeedback.type : 'info'" show>
                <span class="d-block">{{ formKidFeedback.msg }}</span>
              </b-alert>
            </b-col>
          </b-row>
          
          <b-row>
            <b-col>
              <b-alert variant="info" show>
                <span class="d-block">Mit Eingabe der Swiss Athletics Lizenz Nummer oder der KidID und dem Geburtsdatum werden die untenstehenden Felder automatisch ausgefüllt. Sollten deine Angaben fehlerhaft oder unvollständig sein, dann teile uns die Korrekturen per E-Mail an <b-link href="mailto:nachwuchsprojekte@swiss-athletics.ch">nachwuchsprojekte@swiss-athletics.ch</b-link> mit</span>
              </b-alert>
            </b-col>
          </b-row>

          <b-row align-h="end">
            <b-col order="2" order-md="1" cols="12" xl="4" lg="5" md="6">
              <b-button v-if="form.kidId === ''" variant="light" @click="switchToRegisterForm" block>
                Ohne Swiss Athletics Nummer anmelden
              </b-button>
            </b-col>
            <b-col order="1" order-md="2" cols="12" xl="2" lg="3" md="4" class="mb-3 mb-md-0">
              <b-button type="submit" block>
                Weiter
              </b-button>
            </b-col>
          </b-row>
          
          <!-- <b-row align-h="between" class="my-3">
            <b-col>
              <hr>
            </b-col>
          </b-row> -->

        </b-form>
      </ValidationObserver>
    </b-col>
    <b-col v-show="displayRegisterForm && displayKidRegisterForm">
      <b-row align-h="between" class="my-3">
        <b-col>
          <hr>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" v-show="displayRegisterForm">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
        <b-form @submit.prevent="handleSubmit(registerAthlete)">

          <b-row v-if="helpers.disableKidId">
            <!-- Feld: KidID -->
            <b-col sm="6">
              <ValidationProvider
                name="KidID"
                rules=""
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-kidId"
                  label="KidID"
                  label-for="input-kidId"
                >
                  <b-form-input
                    id="input-kidId"
                    v-model="form.kidId"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-kidId"
                    size="sm"
                    :disabled="helpers.disableKidId"
                  ></b-form-input>
                  <b-form-invalid-feedback id="feedback-kidId">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                  <small v-if="helpers.disableKidId" @click="resetAthleteNumber">
                    <b-link @click="resetAthleteNumber">
                      Verbindung zu Swiss Athletics Konto aufheben
                    </b-link>
                  </small>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <!-- Feld: Vorname -->
            <b-col sm="6">
              <ValidationProvider
                name="Vorname"
                rules="required"
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-firstName"
                  label="Vorname *"
                  label-for="input-firstName"
                >
                  <b-form-input
                    id="input-firstName"
                    v-model="form.firstName"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-firstName"
                    size="sm"
                    :disabled="helpers.disableFirstName"
                  ></b-form-input>
                  <b-form-invalid-feedback id="feedback-firstName">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            
            <!-- Feld: Nachname -->
            <b-col sm="6">
              <ValidationProvider
                name="Nachname"
                rules="required"
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-lastName"
                  label="Nachname *"
                  label-for="input-lastName"
                >
                  <b-form-input
                    id="input-lastName"
                    v-model="form.lastName"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-lastName"
                    size="sm"
                    :disabled="helpers.disableLastName"
                  ></b-form-input>
                  <b-form-invalid-feedback id="feedback-lastName">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <!-- Feld: Jahrgang -->
            <b-col sm="6">
              <ValidationProvider
                name="Jahrgang"
                rules="required"
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-ageGroup"
                  label="Jahrgang *"
                  label-for="input-ageGroup"
                >
                  <b-form-select
                    id="input-ageGroup"
                    v-model="form.ageGroup"
                    :options="selectFields.ageGroup"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-ageGroup"
                    size="sm"
                    :disabled="helpers.disableAgeGroup"
                  ></b-form-select>
                  <b-form-invalid-feedback id="feedback-ageGroup">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            
            <!-- Feld: Geschlecht -->
            <b-col sm="6">
              <ValidationProvider
                name="Geschlecht"
                rules="required"
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-sex"
                  label="Geschlecht *"
                  label-for="input-sex"
                >
                  <b-form-select
                    id="input-sex"
                    v-model="form.sex"
                    :options="selectFields.sex"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-sex"
                    size="sm"
                    :disabled="helpers.disableSex"
                  ></b-form-select>
                  <b-form-invalid-feedback id="feedback-sex">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <!-- Feld: Adresse -->
            <b-col>
              <ValidationProvider
                name="Adresse"
                rules="required"
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-addressLine1"
                  label="Adresse *"
                  label-for="input-addressLine1"
                >
                  <b-form-input
                    id="input-addressLine1"
                    v-model="form.addressLine1"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-addressLine1"
                    size="sm"
                  ></b-form-input>
                  <b-form-invalid-feedback id="feedback-addressLine1">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          
          <b-row>
            <!-- Feld: PLZ -->
            <b-col sm="4" md="3">
              <ValidationProvider
                name="PLZ"
                rules="required|numeric|max:4"
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-zip"
                  label="PLZ *"
                  label-for="input-zip"
                >
                  <b-form-input
                    id="input-zip"
                    v-model="form.zip"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-zip"
                    size="sm"
                  ></b-form-input>
                  <b-form-invalid-feedback id="feedback-zip">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            
            <!-- Feld: Ort -->
            <b-col sm="8" md="9">
              <ValidationProvider
                name="Ort"
                rules="required"
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-city"
                  label="Ort *"
                  label-for="input-city"
                >
                  <b-form-input
                    id="input-city"
                    v-model="form.city"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-city"
                    size="sm"
                  ></b-form-input>
                  <b-form-invalid-feedback id="feedback-city">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <!-- Feld: Kanton -->
            <b-col>
              <ValidationProvider
                name="Kanton"
                rules="required"
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-canton"
                  label="Kanton *"
                  label-for="input-canton"
                >
                  <b-form-select
                    id="input-canton"
                    v-model="form.canton"
                    :options="selectFields.canton"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-canton"
                    size="sm"
                  ></b-form-select>
                  <b-form-invalid-feedback id="feedback-canton">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <!-- Feld: Verein -->
            <b-col>
              <ValidationProvider
                name="Verein"
                rules=""
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-club"
                  label="Verein"
                  label-for="input-club"
                >
                  <b-form-input
                    id="input-club"
                    v-model="form.club"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-club"
                    size="sm"
                  ></b-form-input>
                  <b-form-invalid-feedback id="feedback-club">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          
          <b-row>
            <b-col sm="6">
            <!-- Feld: E-Mail -->
              <ValidationProvider
                name="E-Mail"
                rules="required|email"
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-email"
                  label="E-Mail *"
                  label-for="input-email"
                >
                  <b-form-input
                    id="input-email"
                    v-model="form.email"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-email"
                    size="sm"
                  ></b-form-input>
                  <b-form-invalid-feedback id="feedback-email">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="6">
            <!-- Feld: E-Mail Typ -->
              <ValidationProvider
                name="E-Mail Typ"
                rules="required"
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-emailType"
                  label="E-Mail Typ *"
                  label-for="input-emailType"
                >
                  <b-form-select
                    id="input-emailType"
                    v-model="form.emailType"
                    :options="selectFields.emailType"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-emailType"
                    size="sm"
                  ></b-form-select>
                  <b-form-invalid-feedback id="feedback-emailType">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          
          <b-row>
            <!-- Feld: Sprache -->
            <b-col sm="6">
              <ValidationProvider
                name="Sprache"
                rules=""
                v-slot="validationContext"
              >
                <b-form-group
                  id="fieldset-language"
                  label="Sprache"
                  label-for="input-language"
                >
                  <b-form-select
                    id="input-language"
                    v-model="form.language"
                    :options="selectFields.language"
                    :state="getValidationState(validationContext)"
                    aria-describedby="feedback-language"
                    size="sm"
                    class="mt-1"
                  ></b-form-select>
                  <b-form-invalid-feedback id="feedback-language">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <!-- Feld: Einverständniss der Teilnahmebedingungen -->
              <b-form-checkbox
                id="input-consent"
                v-model="helpers.consent"
                :value="true"
                :unchecked-value="false"
                class="mb-3"
              >
                Ich habe die <b-link :href="`https://sprint.test-swiss-athletics.ch/${$i18n.locale}/event/teilnahmebedingungen/`" target="_blank">Teilnahmebedingungen</b-link> gelesen und erkläre mich damit einverstanden.
                <small v-if="customError.consent.display" class="d-block text-danger">{{ customError.consent.message }}</small>
              </b-form-checkbox>
            </b-col>
          </b-row>
          
          <b-row align-h="between">
            <b-col cols="6" xl="2" lg="3" md="4">
              <b-button :to="`/${$i18n.locale}/veranstaltungen/${event.id}`" variant="light" block>
                Zurück
              </b-button>
            </b-col>
            <b-col cols="6" xl="2" lg="3" md="4">
              <b-button type="submit" block>
                Anmelden
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </ValidationObserver>
    </b-col>
    <b-col v-show="formFeedback.display">
      <b-alert :variant="formFeedback.type ? formFeedback.type : 'info'" show>
        <span class="d-block">{{ formFeedback.msg }}</span>
        <b-link @click="backToForm" v-show="formFeedback.backLink">
          Zurück zum Formular
        </b-link>
      </b-alert>
    </b-col>
    <div
      v-if="displayPageLoader"
      id="pageLoader"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      user: null,
      formKid: {
        satMemberNumber: "", // Swiss Athletics Nummer
        birthDate: "" // Geburtstag
      },
      form: {
        nwpId: this.event.id, // Id des Wettkampfes
        kidId: '', // Kid Id
        firstName: '', // Vorname
        lastName: '', // Nachname
        ageGroup: '', // Jahrgang
        sex: '', // Geschlecht
        addressLine1: '', // Adresse
        zip: '', // PLZ
        city: '', // Ort
        canton: '', // Kanton
        club: '', // Verein
        email: '', // E-Mail
        emailType: '', // E-Mail Typ
        language: '' // Sprache
      },
      selectFields: {
        ageGroup: [],
        sex: [],
        canton: [],
        emailType: [],
        language: []
      },
      helpers: {
        consent: false,
        disableKidId: false,
        disableFirstName: false,
        disableLastName: false,
        disableAgeGroup: false,
        disableSex: false
      },
      formFeedback: {
        display: false,
        backLink: false,
        registerLink: false,
        type: "",
        msg: ""
      },
      formKidFeedback: {
        display: false,
        type: "",
        msg: ""
      },
      customError: {
        consent: {
          display: false,
          message: ""
        }
      },
      displayForm: true,
      displayKidRegisterForm: true,
      displayRegisterForm: false,
      displayPageLoader: false
    };
  },
  props: {
    event: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  created() {
    this.getLanguage();
    this.getCantonsList();
    this.getEmailTypeList();
    this.getSex();
    this.calculateAgeGroups();
  },
  methods: {
    calculateAgeGroups() {
      var minAge = 4;
      var maxAge = 15; // Max. 15 Jahre ist vordefiniert
      var minYear = 0;
      var maxYear = 0;
      var currentYear = new Date().getFullYear();
      var youngestCategory = this.event.youngestCategory || false;
      
      // Parse min. Alter aus dem LIC, falls keine lokale Ausscheidung
      if (youngestCategory && this.event.nwpCategoryKey != "Lov.Sat.NWPCategory.SprintLocalContest") {
        var minAge = parseInt(youngestCategory.match(/\d+/).shift());
      }

      minYear = currentYear - minAge;
      maxYear = currentYear - maxAge;

      var ageGroup = [];
      for (let index = maxYear; index <= minYear; index++) {
        ageGroup.push(index);
      }

      this.selectFields.ageGroup = ageGroup.reverse();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    mapLov(arr) {
      return arr.map((obj) => {
        var rObj = { value: "", text: "" };
        rObj.value = obj.lic;
        rObj.text = this.$t(obj.lic);
        return rObj;
      });
    },
    getCantonsList() {
      this.$store
        .dispatch("lov/getCantonList")
        .then((resp) => {
          this.selectFields.canton = this.mapLov(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLanguage() {
      this.$store
        .dispatch("lov/getLanguage")
        .then((resp) => {
          this.selectFields.language = this.mapLov(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEmailTypeList() {
      this.$store
        .dispatch("lov/getEmailType")
        .then((resp) => {
          this.selectFields.emailType = this.mapLov(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSex() {
      this.$store
        .dispatch("lov/getSex")
        .then((resp) => {
          this.selectFields.sex = this.mapLov(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    backToForm() {
      this.resetFormFeedback();
      this.displayRegisterForm = true;
    },
    prefillForm(data, lock = false) {
      this.form.kidId = data.satMemberNumber;
      this.form.firstName = data.firstName;
      this.form.lastName = data.lastName;
      this.form.ageGroup = data.ageGroup;
      this.form.sex = data.sex;
      this.form.addressLine1 = data.addressLine1;
      this.form.zip = data.zip;
      this.form.city = data.city;
      this.form.canton = data.canton;
      this.form.club = data.club;
      this.form.email = data.email;
      this.form.language = data.language;

      if (lock) {
        this.disableKidId = true;
        this.disableFirstName = true;
        this.disableLastName = true;
        this.disableAgeGroup = true;
        this.disableSex = true;
      }
    },
    resetFormFeedback() {
      this.formFeedback.display = false;
      this.formFeedback.type = "";
      this.formFeedback.msg = "";
      this.formFeedback.backLink = false;
      this.formFeedback.registerLink = false;
      
      this.formKidFeedback.display = false;
      this.formKidFeedback.type = "";
      this.formKidFeedback.msg = "";
    },
    resetAthleteNumber() {
      this.$emit('reset-einzelanmeldung')
    },
    switchToRegisterForm() {
      this.displayKidRegisterForm = false;
      this.displayRegisterForm = true;
    },
    switchToKidRegisterForm() {
      this.displayKidRegisterForm = true;
      this.displayRegisterForm = false;
    },
    registerAthleteNumber() {
      this.displayPageLoader = true;
      try {
        var dateArr = this.formKid.birthDate.split(".");
        var date = new Date(dateArr[2], parseInt(dateArr[1]) - 1, dateArr[0]); // Subtract 1 from month because month parameter must be an array-index (0 = january to 11 = december)
        var birthDate = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0"); // Add 1 to month because getMonth() returns the index (0 = january to 11 = december)
      } catch (error) {
        console.log("Error on Date processing", error);
        this.displayPageLoader = false;
        return;
      }

      if (!this.selectFields.ageGroup.includes(date.getFullYear())) {
        this.formKidFeedback.msg =
            "Der Jahrgang des angegebenen Swiss Athletics Kontos befindet sich nicht im zugelassenen Bereich dieses Wettkampfes.";
          this.formKidFeedback.type = "danger";
          this.formKidFeedback.display = true;
          this.displayPageLoader = false;
          return;
      }

      var data = {
        satMemberNumber: this.formKid.satMemberNumber,
        birthDate: birthDate
      };

      // Hohle Kontodaten via Swiss Athletics Nummer
      this.$store
        .dispatch("wettkampf/getTeilnehmer", data)
        .then((resp) => {
          if (resp.status === 204) {
            this.formKidFeedback.msg = "Für die angegebenen Konto Daten konnten kein passendes Swiss Athletics Konto geladen werden.";
            this.formKidFeedback.type = "danger";
            this.formKidFeedback.display = true;
          }
          if (resp.status === 200) {
            this.prefillForm(resp.data, true);
            this.switchToRegisterForm();
            this.resetFormFeedback();
          }
        })
        .catch(() => {
          this.formKidFeedback.msg =
            "Beim Laden deiner Swiss Athletics Kontodaten ist ein Fehler aufgetretten.";
          this.formKidFeedback.type = "danger";
          this.formKidFeedback.display = true;
        })
        .then(() => {
          this.displayPageLoader = false;
        });
    },
    registerAthlete() {
      this.displayPageLoader = true;

      // Prüfe ob Teilnahmebedingungen akzeptiert wurden (breche anmeldung ab, falls nicht)
      if (this.helpers.consent === false) {
        this.customError.consent.message = "Sie müssen die Teilahmebedingungen akzeptieren um mit der Anmeldung fortfahren zu können";
        this.customError.consent.display = true;
        this.displayPageLoader = false;
        return;
      }

      // Sende formular daten an api
      this.$store
        .dispatch("wettkampf/setTeilnehmer", this.form)
        .then(() => {
          this.displayRegisterForm = false; // Hide Form on success
          this.formFeedback.msg = "Du hast dich erfolgreich für diesen Wettkampf angemeldet.";
          this.formFeedback.type = "success";
          this.formFeedback.display = true;
          this.formFeedback.registerLink = true;
          setTimeout(() => {
            this.$router.push(`/${this.$i18n.locale}/veranstaltungen/${this.event.id}`);
          }, 5000);
        })
        .catch(() => {
          this.displayRegisterForm = false; // Hide Form on success
          this.formFeedback.msg =
            "Bei der Anmeldung für diesen Wettkampf ist ein Fehler aufgetretten.";
          this.formFeedback.type = "danger";
          this.formFeedback.backLink = true;
          this.formFeedback.display = true;
        })
        .then(() => {
          this.displayPageLoader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#pageLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(239, 239, 239, 0.6);
}
</style>
